import React, { useRef, useEffect, useState } from "react"
import mapboxgl from "!mapbox-gl" // eslint-disable-line import/no-webpack-loader-syntax

import "mapbox-gl/dist/mapbox-gl.css"
import "./mapbox.css"

mapboxgl.accessToken = process.env.GATSBY_MAPBOX_ACCESS_TOKEN

const Mapbox = ({ longitude, latitude, city, customZoom = 13 }) => {
  const mapContainerRef = useRef(null)
  const [geoJson, setGeoJson] = useState(null)

  useEffect(() => {
    // Dynamically import the GeoJSON file based on the city name
    import(`../data/${city}.json`)
      .then((module) => setGeoJson(module.default))
      .catch((error) =>
        console.error(`Failed to load GeoJSON for ${city}:`, error)
      )
  }, [city])

  useEffect(() => {
    if (!geoJson) return

    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: "mapbox://styles/mapbox/streets-v12",
      center: [longitude, latitude],
      zoom: customZoom,
    })

    // Add navigation control (the +/- zoom buttons)
    map.addControl(new mapboxgl.NavigationControl(), "top-right")

    // Function to handle "Hébergement" category
    const handleHebergementMarker = (feature) => {
      const { coordinates } = feature.geometry
      const { name, address, website, category, subcategory, review } =
        feature.properties

      const popup = new mapboxgl.Popup({ offset: 25 }).setHTML(`
        <h3><a href="${website}" target="_blank" rel="noopener noreferrer">${name}</a></h3>
        <p><strong>${category}</strong> - ${subcategory}</p>
        <p><i>${address}</i></p>
        ${review ? `<p>${review}</p>` : ""}
      `)

      new mapboxgl.Marker({ color: "#1a73e8" }) // Distinct color for Hébergement
        .setLngLat(coordinates)
        .setPopup(popup)
        .addTo(map)
    }

    // Function to handle "A voir" category
    const handleAvoirMarker = (feature) => {
      const { geometry, properties } = feature
      const { coordinates } = geometry

      // For "A voir" points, add a basic marker with the name as a title on hover
      new mapboxgl.Marker({ color: "#b8c6db" }) // Different color for "A voir"
        .setLngLat(coordinates)
        .setPopup(new mapboxgl.Popup({ offset: 25 }).setText(properties.name)) // Simple name popup on hover
        .addTo(map)
    }

    // Loop through geoJson features
    geoJson.features.forEach((feature) => {
      const { category } = feature.properties

      if (category === "Hébergement") {
        handleHebergementMarker(feature)
      } else if (category === "A voir") {
        handleAvoirMarker(feature)
      }
    })

    // Clean up on unmount
    return () => map.remove()
  }, [geoJson, longitude, latitude, customZoom])

  return <div className="map-container" ref={mapContainerRef} />
}

export default Mapbox
