const getFrenchPreposition = (cityName) => {
  // Normalize city name to lowercase
  const normalizedCityName = cityName.toLowerCase().trim()

  // Check if the city name starts with a vowel (for "d'")
  const startsWithVowel = /^[aeiouyh]/i.test(normalizedCityName)

  return startsWithVowel ? `d'${cityName}` : `de ${cityName}`
}

module.exports = getFrenchPreposition
