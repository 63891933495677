import React from "react"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBed, faEye, faMap } from "@fortawesome/free-solid-svg-icons"
import normalizeCityName from "../utils/normalizeCityName"

import "./footer.css"

const Footer = ({ city }) => {
  const citySlug = normalizeCityName(city)
  return (
    <footer>
      <nav>
        <ul id="quick-actions" className="bar no-print uppercase">
          <li>
            <Link
              to={`/carte-touristique-${citySlug}`}
              aria-label={`Carte touristique ${city}`}
            >
              <FontAwesomeIcon icon={faMap} />
              <span>{city}</span>
            </Link>
          </li>
          <li>
            <Link
              to={`/se-loger-a-${citySlug}`}
              aria-label={`Se loger à ${city}`}
            >
              <FontAwesomeIcon icon={faBed} />
              <span>Se loger</span>
            </Link>
          </li>
          <li>
            <Link
              to={`/week-end-a-${citySlug}-les-incontournables`}
              aria-label={`A voir à ${city}`}
            >
              <FontAwesomeIcon icon={faEye} />
              <span>A voir</span>
            </Link>
          </li>
        </ul>
      </nav>
    </footer>
  )
}

export default Footer
