import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Mapbox from "../components/mapbox"
import Footer from "../components/footer"
import normalizeCityName from "../utils/normalizeCityName"
import getFrenchPreposition from "../utils/getFrenchPreposition"

export const Head = ({ pageContext }) => {
  const { city } = pageContext
  const formattedCityName = getFrenchPreposition(city)

  return (
    <Seo
      title={`Carte touristique ${formattedCityName}`}
      description={`Découvrez notre carte touristique ${formattedCityName} pour explorer les meilleures attractions, points d'intérêt et obtenir des conseils pratiques pour votre visite.`}
    />
  )
}

const MapPage = ({ pageContext }) => {
  const { city, latitude, longitude } = pageContext
  const normalizedCityName = normalizeCityName(city)

  return (
    <Layout content="map">
      <Mapbox
        latitude={parseFloat(latitude)}
        longitude={parseFloat(longitude)}
        city={normalizedCityName} // pass the city name in lowercase to load the correct JSON file
      />
      <Footer city={city} />
    </Layout>
  )
}

export default MapPage
